body {
  background-color: #282c34;
}
.sideBar {
  width: 17%;
  padding: 10px;
  margin: 10px;
  box-shadow: 2px 0 5px #202020;
    border-radius: 13px;
    border: 1px solid #222;
}

.sideBarMinimized {
  width: 100px;
}

.sideBar h2 {
  margin: 4px 7px;
}
.backr {
  background:rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 95;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
}
.channel {
  float:left;
  color:#fff;
  font-size:26px;
}

.channel button {
  border: 0;
    background: none;
    color: #fff;
    font-size: 23px;
    text-decoration: underline;
    cursor: pointer;
}
.channel a {
  color:#fff;
}
.twitter-share-button img {
  width: 58px
}
.main {
  display: flex;
  flex-direction:row;
}
.main h2 {
  color:#fdfdfd;
}
.channels {
  display: inline-block;
}

.channelsFull {
  z-index: 99;
  position: fixed;
  top: 10px;
  left: 373px;
  width: 285px;
  padding: 15px;
  background: #444952;
  border-radius: 7px;
}

.qrinfoBox {
  color: rgb(0, 0, 0);
  background: #585f6e;
  display: flex;
  padding: 5px 0px;
  margin-left: 0px;
  margin-top: -2px;
  width: 200px;
  align-items: center;
}

.iframe-player {
  width:100%;
  height: 100%;
}
.container {
  width:80%;
  height: 500px;
  clear: both;
}
.playerFrame {
  position: absolute;
  width: 75%;
    height: 570px;
    z-index: 99;
    padding: 0 20px;
}

.playerFrameMax {
  position: absolute;
  width: 1160px;
    height: 655px;
    z-index: 99;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.channel-input input {
  margin:0 5px;
  font-size: 22px;
  padding: 10px;
  background:#585f6e;
  color:#fdfdfd;
  border: 2px;
  border-radius: 12px;
  width: 90%;
}

.channel-input input:focus {
  border: 10px;
}

.channelrow {
  color:#bdbdbd;
}

.zapping-buttons {
margin:5px 3px;
display: flex;
align-items: self-start;
}

button {
  background: #585f6e;
  border: 2px solid #bdbdbd;
  border-radius: 10px;
  color: #bdbdbd;
  margin: 5px 3px;
  font-size: 30px;
  position: relative;
  height: 45px;
  z-index: 98;
}


@media(min-width:1400px){

  .sideBar {
    width: 17%;
  }

  .playerFrame {
    position: absolute;
    width: 80%;
      height: 865px;
      z-index: 99;
      padding: 0;
  }

  .playerFrameMax {
    position: absolute;
    width: 1680px;
      height: 945px;
      z-index: 99;
  }

  .channel-input input {
    width: 90%;
  }
}

@media(max-width:900px){
  .sideBar {
    width: 100%;
  }

  .container {
    width: 1px;
  }
  .channelsFull {
    z-index: 99;
    position: fixed;
    top: 482px;
    left: 26px;
    width: 285px;
    padding: 15px;
    background: #444952;
    border-radius: 7px;
  }

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
